import React from 'react';
//= Layout
import MainLayout from 'layouts/Main';
//= Components
import Navbar from "components/Navbars/ITCreativeNav";
import Blog from 'components/Software/Blog';
import ChatBanner from 'components/Software/ChatBanner';
import Footer from 'components/Saas/Footer';
import { Link } from 'gatsby';
import Blog6 from 'components/Software/Blog6';
import Codei from 'components/Preview/Codei';



const SmeLoan = () => {
  return (
    <MainLayout>
      <Navbar />
      <header className="style-3 overflow-hidden" data-scroll-index="0" style={{background: '#212529'}}>
      <div className="container">
        <div className="content section-padding">
          <div className="row">
            <div className="col-lg-5">
              <div className="info" style={{ background: '#00000000'  }}>
                <h1 className="h1">Beta Moni, Beta    <span> Business</span></h1>
                <p className="p">Empower your business with our SME Loans</p>
               
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-img ">      
        
        <img src="/assets/img/about/style_3_1.png" style={{ maxWidth: '30%'}} alt="" /> 
        <img src="/assets/img/about/SME LOAN.png" alt="" className='img-body ' />     
      </div>
    </header>
      <main>
      <section className="about section-padding style-3" data-scroll-index="3">
      
        <div className="container">
          <div className="row gx-0 justify-content-end">
            <div className="col-lg-6">
            <div className="img ">
          <img src="/assets/img/about/SME LOAN.png" alt="" className='img-body ' />          
        </div>
            </div>
            <div className="col-lg-6">
              <div className="info">
                <div className="section-head long-shape mb-40 style-3">
                  <h3>SME <span> Loan</span></h3>
                </div>
                <p>
                Empower your business with our SME Loans tailored specifically for small and medium enterprises. With favorable terms and fast approval, we help SMEs access the capital needed for expansion, working capital, or equipment financing
                </p>
               
                <div className="text mb-20 mt-20">
                  <h5>Requirements: </h5>
                  <ul>
                    <li>Registered Business</li>
                    <li>CAC Documents</li>
                    <li>KYC verification</li>
                    <li>BVN</li>                                 
                                      
                  </ul>
                </div>
               
                <Link to="/contact" className="btn rounded-pill bg-blue2 sm-butn text-white mt-10">
                  <span>Apply for SME Loan</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
    
    </section>
       
        <Blog6 />
        <Codei /> 
        <ChatBanner />
      </main>
      <Footer />
    </MainLayout>
  )
}

export const Head = () => {
  return (
    <>
      <title> SME Loans – Get A Quick SME Loan for Your Business</title>
      <meta name="description" content="Access quick SME Loan in Nigeria with flexible repayment term. Get fast financing solutions for your SME business. Apply now to unlock your business's potential" />
      <link rel="stylesheet" href="/assets/css/lib/bootstrap.min.css" />
      <link rel="stylesheet" href="/assets/css/style.css" />
    </>
  )
}

export default SmeLoan;